.new-list .modal-dialog {
    max-width: 600px;
}

.new-list .modal-content{
    border-radius: 15px;
    box-shadow: 0 10px 30px rgb(0 0 0 / 20%);
}

.new-list .modal-title{
    font-size: 17px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

.new-list .modal-body {
    overflow-y: scroll;
}

.new-list .user-list {
    height: 300px;
    position: relative;
    z-index: 0;
}

.new-list .user-list .user-content{
    padding-bottom: 3px;
}

input[type="checkbox"]:checked ~ .splist-card.card {
    border: 1px solid #EDF2F9 !important;
    background: #EDF2F9 0% 0% no-repeat padding-box !important;
}

.splist-card.card {
    flex-direction: inherit;
    border-radius: 13px;
    border: 1px solid #EBEBEB;
    background: #ffff 0% 0% no-repeat padding-box;
    align-items: center;
    margin-bottom: 13px;
    cursor: pointer;
}

.splist-card.card .card-body{
 padding: 0px;
}

.splist-card.card .card-img {
    width: 60px;
    border-radius: 35px;
    object-fit: cover;
    padding: 10px;
}

.splist-card.card .sp-info{
    display: flex;
    flex-direction: column;
    padding: 10px 0px;
}

.splist-card.card .sp-info label {
    font-size: 16px;
    font-family: 'Inter', sans-serif!important;
    font-weight: 500;
    color: #222222;
}

input[type="checkbox"] {
    display: none;
}

.new-list .modal-footer{
    justify-content: center;
}

.new-list .modal-footer button{
    border-radius: 8px;
    background: #2c7be5;
    font-size: 15px;
    font-family: "Inter", sans-serif;
    padding: 10px 20px;
    border: none;
    color: white;
}