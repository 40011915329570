#messages {
  overflow: hidden;
}
.name{
  padding-left: 20px;
}
.dropdown {
  background-color: white;
  border: medium none;
  border-radius: 50%;
  color: rgb(18, 14, 14);
  cursor: pointer;
  font-size: 20px;
  height: 35px;
  width: 35px;
}
#messages .messaging .inbox_people {
  overflow: hidden;
  border-right: 1px solid #e1e1e1;
  padding: 0;
}

#messages .messaging .inbox_people .list_tab .nav-tabs{
  align-items: flex-end;
  padding: 0px 20px;
}

#messages .messaging .inbox_people .list_tab .nav-tabs .nav-link {
   padding: 15px 40px;
  font-size: 17px;
  font-weight: 500;
  font-family: 'Inter', sans-serif;
  border: none;
  color: #afafaf;
 
}

#messages .messaging .inbox_people .list_tab .nav-tabs .nav-link.active {
  border-bottom: 4px solid #2c7be5!important;
  color: #3a3a3a;
}

#messages .messaging .inbox_msg {
  clear: both;
  overflow: hidden;
}

#messages .messaging .top_spac {
  margin: 20px 0 0;
}
#messages .messaging .srch_bar {
  display: block;
  padding: 0 30px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
#messages .messaging .headind_srch {
  padding: 0px;
  overflow: hidden;
  border-bottom: 1px solid #eee;
}
#messages .messaging .recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}
#messages .messaging .srch_bar input {
  border: 1px solid #e6e6e6;
  width: 100%;
  background: #f7f7f7;
  height: 50px;
  padding-left: 40px;
  border-radius: 10px;
}
#messages .messaging .srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}
#messages .messaging .srch_bar .input-group-addon {
  position: absolute;
  left: 10px;
  bottom: 15px;
  z-index: 4;
}
#messages .messaging .chat_ib h5 {
  font-size: 18px;
  color: #343434;
  font-weight: 500;
  margin: 0 0 5px 0;
}
#messages .messaging .chat_ib h5 span {
  position: absolute;
  right: 15px;
  font-size: 13px;
  font-weight: 300;
  color: #989898;
}
#messages .messaging .chat_ib p {
  font-size: 15px;
  color: #989898;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
#messages .messaging .chat_people {
  overflow: hidden;
  clear: both;
  display: flex;
}
#messages .messaging .chat_people img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 100%;
}
#messages .messaging .chat_ib {
  padding: 0 0 0 15px;
}

#messages .messaging .chat_list {
  border-bottom: 1px solid #eee;
  margin: 0;
  padding: 15px 30px;
  cursor: pointer;
}
#messages .messaging .inbox_chat {
  height: 550px;
  position: relative;
  z-index: 0;
  overflow-y: auto;
}
#messages .messaging .active_chat {
  background: #f4f8f5;
}
#messages .messaging .incoming_msg_img {
  display: inline-block;
  width: 6%;
}
#messages .messaging .mesgs .contact-profile .detail-icon{
  float: right;
  padding: 0px 30px;
  font-size: 27px;
  cursor: pointer;
}
#messages .messaging .received_msg {
  display: inline-block;
  vertical-align: top;
  width: 92%;
}
#messages .messaging .received_withd_msg p {
  background: #f4f4f4;
  border-radius: 12px 12px 12px 0px;
  color: #3a3a3a;
  font-size: 16px;
  padding: 15px;
  margin-bottom: 10px;
  display: table;
}
#messages .messaging .received_msg .time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
#messages .messaging .sent_msg .time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin: 8px 0 0;
}
#messages .messaging .received_withd_msg {
  display: inline-block;
}
#messages .messaging .sent_msg p {
  background: #2c9548;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 15px;
  border-radius: 12px 12px 0px 12px;
  display: table;
  margin-bottom: 10px;
}
#messages .messaging .outgoing_msg {
  overflow: hidden;
  margin: 20px 0 20px;
}
#messages .messaging .sent_msg {
  float: right;
}
#messages .messaging .input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 55px;
  width: 100%;
  padding-left: 10px;
  outline: none;
}
#messages .messaging .type_msg {
  border-top: 1px solid #e8e8e8;
  position: relative;
}
#messages .messaging .msg_send_btn {
  background: #2c9548;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  height: 35px;
  position: absolute;
  right: 25px;
  top: 11px;
  width: 35px;
}
#messages .messaging .msg_history {
  height: calc(500px - 130px);
  overflow-y: auto;
  padding: 20px 30px;
  width: 100%;
  position: relative;
  z-index: 0;
}
#messages .messaging .btn-attach {
  background: #eaeaea;
  border-radius: 100%;
  color: #3a3a3a;
  cursor: pointer;
  font-size: 18px;
  height: 35px;
  position: absolute;
  right: 70px;
  top: 11px;
  width: 35px;
  border: medium none;
}

#messages .messaging .mesgs{
  padding: 0px;
}

#messages .messaging .mesgs .contact-profile {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #f5f5f5;
  border-bottom: 1px solid #e6e6e6;
}
#messages .messaging .mesgs .contact-profile img {
  width: 40px;
  border-radius: 100%;
  float: left;
  margin: 10px 12px 0 9px;
}
#messages .messaging .mesgs .contact-profile p {
  float: left;
}
#messages .messaging .received_img {
  height: 120px;
  border-radius: 15px;
  cursor: pointer;
  margin-bottom: 15px;
}
#messages .messaging .send_img {
  height: 120px;
  border-radius: 15px;
  cursor: pointer;
}
#messages .messaging .received_video {
  border-radius: 15px;
  width: 180px;
  height: 140px;
}
#messages .messaging .send_video {
  border-radius: 15px;
  width: 180px;
  height: 140px;
}

/*--------------------------------------------*/
/* Laptop with Desktop (1600px) View*/
/*--------------------------------------------*/
@media all and (max-width:1600px){
  #messages .messaging .mesgs .contact-profile .arrow-value{
    display: none;
}
}

/*--------------------------------------------*/
/* Laptop with Desktop (1400px) View*/
/*--------------------------------------------*/
@media all and (max-width:1400px){
  #messages .messaging .mesgs .contact-profile .arrow-value{
    display: none;
}
}


/*--------------------------------------------*/
/* Laptop with Tab (1199px) View*/
/*--------------------------------------------*/
@media all and (max-width:1199px){
  #messages .messaging .mesgs .contact-profile .arrow-value{
    display: none;
}
}

/* ------------------------------------- */
/* Mini laptop and mini desktop View Media Query (1200px) */
/* --------------------------------------*/
@media (max-width: 1200px) {
  #messages .messaging .mesgs .contact-profile .arrow-value{
    display: none;
}

#messages .messaging .inbox_people .list_tab .nav-tabs {
  padding: 0px 0px;
}


}

/* --------------------------------------*/
/* Mini Laptop And All Tab View Media Query (1024px)  */
/* --------------------------------------*/
@media all and (max-width:1024px){
  #messages .messaging .mesgs .contact-profile .arrow-value{
    display: none;
}
}

/*--------------------------------------------*/
/* Mini Laptop with Tab (991px) View*/
/*--------------------------------------------*/
@media all and (max-width: 991px){
  #messages .messaging .mesgs .contact-profile .arrow-value{
    display: none;
}
}


@media all and (max-width:768px) {
  #messages .messaging .mesgs {
    display: none;
 }

 #messages .messaging .inbox_people {
   display: none;
 }

 #messages .messaging .mesgs .contact-profile {
     padding-left: 1.5rem;
     display: flex;
 }

 #messages .messaging .mesgs .contact-profile .arrow-value{
      display: initial;
      cursor: pointer;
 }
}

/* --------------------------------------*/
/* Mini tab And All Tab View Media Query (767px)  */
/* --------------------------------------*/
@media all and (max-width:767px){
  #messages .messaging .mesgs {
    display: none;
 }

 #messages .messaging .inbox_people {
   display: none;
 }

 #messages .messaging .mesgs .contact-profile {
     padding-left: 1.5rem;
     display: flex;
 }

 #messages .messaging .mesgs .contact-profile .arrow-value{
      display: initial;
      cursor: pointer;
 }
}


/* --------------------------------------*/
/* Mini tab And All Mobile View Media Query (575px)  */
/* --------------------------------------*/
@media all and (max-width:575px){
  #messages .messaging .mesgs {
    display: none;
 }

 #messages .messaging .inbox_people {
   display: none;
 }

 #messages .messaging .mesgs .contact-profile {
     padding-left: 1.5rem;
     display: flex;
 }

 #messages .messaging .mesgs .contact-profile .arrow-value{
      display: initial;
      cursor: pointer;
 }
}


/* --------------------------------------*/
/* Mini Mobile And All Mobile View Media Query (480px)  */
/* --------------------------------------*/
@media all and (max-width: 480px) {
  
  #messages .messaging .mesgs {
    display: none;
 }

 #messages .messaging .inbox_people {
   display: none;
 }

 #messages .messaging .mesgs .contact-profile {
     padding-left: 1.5rem;
     display: flex;
 }

 #messages .messaging .mesgs .contact-profile .arrow-value{
      display: initial;
      cursor: pointer;
 }

}


/* --------------------------------------*/
/* Mini Mobile View Media Query (320px)  */
/* --------------------------------------*/
@media all and (max-width: 320px) {

  #messages .messaging .mesgs {
     display: none;
  }

  #messages .messaging .inbox_people {
    display: none;
  }

  #messages .messaging .mesgs .contact-profile {
      padding-left: 1.5rem;
      display: flex;
  }

  #messages .messaging .mesgs .contact-profile .arrow-value{
       display: initial;
       cursor: pointer;
  }

}