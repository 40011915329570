.group-detail .modal-dialog {
    max-width: 600px;
}

.group-detail .modal-content{
    border-radius: 15px;
    box-shadow: 0 10px 30px rgb(0 0 0 / 20%);
}

.group-detail .modal-title{
    font-size: 20px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

.group-detail .group-delete{
    margin: 0rem -22rem -0.5rem auto;
    cursor: pointer;
}

.group-detail .group-delete .material-icons {
    color: #E34724;
}

.group-detail .group-left {
    margin: 0rem -22rem -0.5rem auto;
}

.group-detail .group-left i {
    font-size: 23px;
    color: red;
    cursor: pointer;
}


.group-detail .basic-detail h5 {
    font-size: 17px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    color: #7B818D;
}

.group-detail .basic-detail span {
    color: #152232;
}
 
.table-wrapper {
 height: 270px;
 overflow-y: auto;
 overflow-x: hidden;
}

.group-detail .table-title {
    padding-bottom: 10px;
    margin: 0 0 10px;
}
.group-detail .table-title h2 {
    margin: 6px 0 0;
    font-size: 17px;
    color: #7B818D;
    font-family: "Inter", sans-serif;
    font-weight: 500;
}

.group-detail table.table tr th{
 border-bottom: 2px solid #dee2e6;
}

.group-detail table.table tr .delete-member {
   text-align: center;
}

.group-detail .table-title .add-new {
    float: right;
    height: 30px;
    text-shadow: none;
    min-width: 100px;
    border-radius: 50px;
    line-height: 30px;
    background: #17a2b8;
    border-color: #17a2b8;
    color: white;
    border: none;
    font-size: 13px;
    font-family: "Inter", sans-serif;
    font-weight: 500;
}
.group-detail .table-title .add-new i {
    margin-right: 4px;
}
.group-detail table.table {
    table-layout: fixed;
}
.group-detail table.table tr th, table.table tr td {
    border-color: #e9e9e9;
}
.group-detail table.table th i {
    font-size: 13px;
    margin: 0 5px;
    cursor: pointer;
}
.group-detail table.table th:last-child {
    width: 100px;
}
.group-detail table.table td a {
    cursor: pointer;
    display: inline-block;
    margin: 0 5px;
    min-width: 24px;
}    
.group-detail table.table td a.add {
    color: #27C46B;
}
.group-detail table.table td a.edit {
    color: #FFC107;
}
.group-detail table.table td a.delete {
    color: #E34724;
}
.group-detail table.table td i {
    font-size: 19px;
}
.group-detail table.table td a.add i {
    font-size: 24px;
    margin-right: -1px;
    position: relative;
    top: 3px;
}    
.group-detail table.table .form-control {
    height: 32px;
    line-height: 32px;
    box-shadow: none;
    border-radius: 2px;
}
.group-detail table.table .form-control.error {
    border-color: #f50000;
}
.group-detail table.table td .add {
    display: none;
}

